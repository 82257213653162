<template>
    <div class="sidebar"
         :class="{'sidebar_closed': isMobile && !isMenuOpened}"
         :style="cssVars">
        <div class="sidebar__wrapper">
            <v-layout v-if="isMobile"
                      justify-end
                      class="mt-3 mr-3">
                <v-icon @click="closeMenu">{{ ICONS.CLOSE }}</v-icon>
            </v-layout>
            <ul class="sidebar__list">
                <MenuItem v-for="(parent, index) in menu"
                          :key="index"
                          :parent="parent"
                          :index="index"
                          :mini-variant="miniVariant"
                          :navbar-width="navbarWidth"></MenuItem>
                <li v-if="isMobile" class="sidebar__mobile-item">
                    <Logout :icon-size="18" :bold-text="false"></Logout>
                </li>
            </ul>
            <p class="collapse"
               v-if="!isMobile"
               @click="toggleMenu">
                <v-icon>{{ collapseIcon }}</v-icon>
                <span v-if="!miniVariant" class="ml-4">{{ $t('buttons.collapse_sidebar') }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { fullNavbarWidth, miniNavbarWidth, navbarHeight } from '@/constants/navbar';
import MenuItem from '@/components/menu/MenuItem';
import Logout from '@/components/auth/Logout';
import { ICONS } from '@/constants/icons';
import ability from '@/mixins/ability';
import { mapState } from 'vuex';

export default {
    name: 'Navigation',
    mixins: [ability],
    components: { MenuItem, Logout },
    data: () => ({
        ICONS,
        sound: new Audio(require('@/assets/alert.mp3'))
    }),
    computed: {
        ...mapFields('navigation', [
            'isMenuOpened'
        ]),
        ...mapState('incidents', [
            'newIncidentsNotification'
        ]),
        menu () {
            return [
                // {
                //     title: this.$t('navigation.home'),
                //     icon: ICONS.HOME,
                //     route: 'home',
                //     visibility: true,
                //     dataTest: 'home'
                // },
                {
                    title: this.$t('navigation.reports'),
                    icon: ICONS.CHART,
                    visibility: this.newReportsTabHasVisibleChildren &&
                        this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.REPORT_TAB),
                    dataTest: 'reports',
                    children: [
                        {
                            title: this.$t('navigation.planning_reports'),
                            route: 'planningReports',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.CUSTOM_REPORTS)
                        },
                        {
                            title: this.$t('navigation.inspections_infringement_report'),
                            route: 'inspectionsInfringementReport',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.CUSTOM_REPORTS),
                            dataTest: 'inspectionsInfringementReport'
                        },
                        {
                            title: this.$t('navigation.global_violations_report'),
                            route: 'globalViolationsReport',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.CUSTOM_REPORTS),
                            dataTest: 'inspectionsInfringementReport',
                            divider: true
                        },
                        {
                            title: this.$t('navigation.incidents_types_reports'),
                            route: 'incidentsTypesReport',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.CUSTOM_REPORTS),
                            dataTest: 'incidentsTypesReport'
                        },
                        {
                            title: this.$t('navigation.incidents_quantity'),
                            route: 'incidentsQuantity',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.CUSTOM_REPORTS),
                            divider: true
                        },
                        {
                            title: this.$t('navigation.custom_reports'),
                            route: 'customReports',
                            visibility: false,
                            dataTest: 'customReports',
                            divider: true
                        },
                        {
                            title: this.$t('navigation.time_reports'),
                            route: 'eztReports',
                            visibility: true,
                            dataTest: 'customReports',
                            divider: true
                        },
                        {
                            title: this.$t('navigation.scoring'),
                            route: 'sestLuvReportsComments',
                            visibility: true,
                            dataTest: 'customReports'
                        },
                        // Hidden this report type due the error on backend side. As far as we know it's not used.
                        {
                            title: this.$t('navigation.scoring_weeks'),
                            route: 'sestLuvWeeksReports',
                            visibility: false,
                            dataTest: 'customReports',
                            divider: true
                        },
                        {
                            title: this.$t('navigation.nfc_reports'),
                            route: 'nfcReports',
                            visibility: false,
                            dataTest: 'nfcReports'
                        }
                    ]
                },
                {
                    title: this.$t('navigation.inspections'),
                    icon: ICONS.CHECKBOX_MARKED,
                    route: 'inspectionsSummary',
                    dataTest: 'inspectionsSummary',
                    visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.INSPECTION) &&
                        this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.INSPECTION_TAB)
                },
                {
                    title: this.$t('navigation.incidents'),
                    icon: ICONS.ALERT,
                    route: 'incidents',
                    dataTest: 'incidents',
                    visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.INCIDENT) &&
                        this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.INCIDENT_TAB),
                    hasNew: this.newIncidentsNotification
                },
                {
                    title: this.$t('navigation.tracks'),
                    icon: ICONS.TRACKS,
                    route: 'tracks',
                    visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.TRACKS) &&
                        this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.TRACKS_TAB)
                },
                {
                    title: this.$t('navigation.customers_and_sites'),
                    icon: ICONS.ACCOUNT_GROUP,
                    dataTest: 'customersAndSites',
                    visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.CUSTOMER) &&
                        this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.CUSTOMER_TAB),
                    children: [
                        {
                            title: this.$t('navigation.customers'),
                            route: 'customers',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.CUSTOMER)
                        },
                        {
                            title: this.$t('navigation.sites'),
                            route: 'sites',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.SITE)
                        },
                        {
                            title: this.$t('management.customers_and_sites.locations_table.title'),
                            route: 'locations',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.LOCATION)
                        }]
                },
                {
                    title: this.$t('navigation.persons'),
                    icon: ICONS.ACCOUNT,
                    route: 'employees',
                    dataTest: 'employees',
                    visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.EMPLOYEE) &&
                        this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.EMPLOYEE_TAB)
                },
                {
                    title: this.$t('navigation.subcontractors'),
                    icon: ICONS.ACCOUNT_SWITCH,
                    route: 'subContractors',
                    dataTest: 'subContractors',
                    visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.SUBCONTRACTORS) &&
                        this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.SUBCONTRACTORS_TAB)
                },
                {
                    title: this.$t('navigation.elements'),
                    icon: ICONS.ELEMENT,
                    route: 'elements',
                    dataTest: 'elements',
                    visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.EMPLOYEE) &&
                        this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.EMPLOYEE_TAB)
                },
                {
                    title: this.$t('navigation.schedule'),
                    icon: ICONS.CALENDAR_CHECK,
                    route: 'scheduling',
                    dataTest: 'scheduling',
                    visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.INSPECTION_SCHEDULE) &&
                        this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.SCHEDULING_TAB)
                },
                {
                    title: this.$t('navigation.configuration'),
                    icon: ICONS.CONFIGURATION,
                    visibility: this.configurationTabHasVisibleChildren &&
                        this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.CONFIGURATION_TAB),
                    dataTest: 'configuration',
                    children: [
                        {
                            title: this.$t('navigation.cities'),
                            route: 'areaConfigurations',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.CITY),
                            dataTest: 'areaConfigurations'
                        },
                        {
                            title: this.$t('navigation.templates'),
                            route: 'templatesConfigurations',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.TEMPLATE),
                            dataTest: 'templatesConfigurations'
                        },
                        {
                            title: this.$t('navigation.positions'),
                            route: 'positions',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.POSITION)
                        },
                        {
                            title: this.$t('navigation.incidents_types_new'),
                            route: 'incidentsTypes',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.INCIDENT_TYPES)
                        },
                        {
                            title: this.$t('navigation.statuses'),
                            route: 'incidentsStatusesConfigurations',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.INCIDENT_STATUS)
                        },
                        {
                            title: this.$t('navigation.subcontractors_types'),
                            route: 'subcontractorsTypes',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.SUBCONTRACTORS_TYPES)
                        },
                        {
                            title: this.$t('navigation.object_groups'),
                            route: 'sitesGroupsConfigurations',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.SITE_GROUP),
                            dataTest: 'sitesGroupsConfigurations'
                        },
                        {
                            title: this.$t('navigation.persons_groups'),
                            route: 'employeesGroupsConfigurations',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.EMPLOYEE_GROUP),
                            dataTest: 'employeesGroupsConfigurations'
                        },
                        {
                            title: this.$t('navigation.data_imports'),
                            route: 'imports',
                            visibility: this.canImportData,
                            dataTest: 'imports'
                        }
                    ]
                },
                {
                    title: this.$t('navigation.settings'),
                    icon: ICONS.SETTINGS,
                    visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.SYSTEM_SETTINGS) &&
                        this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.SETTINGS_TAB),
                    dataTest: 'settings',
                    children: [
                        {
                            title: this.$t('navigation.general'),
                            route: 'configGlobal',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.SYSTEM_SETTINGS)
                        },
                        // {
                        //     title: this.$t('navigation.inspections'),
                        //     route: 'settingsInspections',
                        //     visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.SYSTEM_SETTINGS)
                        // },
                        {
                            title: this.$t('navigation.system_logs'),
                            route: 'systemLog',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.SYSTEM_LOG)
                        },
                        {
                            title: this.$t('labels.translations'),
                            route: 'locales',
                            visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.TRANSLATIONS)
                        },
                        // {
                        //     title: this.$t('navigation.sms_gate'),
                        //     route: 'smsGate',
                        //     visibility: this.$can(this.$abilityActions.READ, this.$abilitySubjects.SYSTEM_SETTINGS)
                        // },
                        {
                            title: this.$t('navigation.holiday_calendar'),
                            route: 'holidays',
                            dataTest: 'holidays',
                            visibility: this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.SYSTEM_SETTINGS)
                        }
                    ]
                }
            ];
        },
        newReportsTabHasVisibleChildren () {
            return this.$can(this.$abilityActions.INCORRECT_INSPECTIONS_REPORT, this.$abilitySubjects.REPORTS) ||
                this.$can(this.$abilityActions.GLOBAL_VIOLATIONS_REPORT, this.$abilitySubjects.REPORTS) ||
                this.$can(this.$abilityActions.INCIDENTS_TYPES_REPORT, this.$abilitySubjects.REPORTS) ||
                this.$can(this.$abilityActions.READ, this.$abilitySubjects.CUSTOM_REPORTS);
        },
        configurationTabHasVisibleChildren () {
            return this.$can(this.$abilityActions.READ, this.$abilitySubjects.CITY) ||
                this.$can(this.$abilityActions.READ, this.$abilitySubjects.TEMPLATE) ||
                this.$can(this.$abilityActions.READ, this.$abilitySubjects.POSITION) ||
                this.$can(this.$abilityActions.READ, this.$abilitySubjects.INCIDENT_TYPES) ||
                this.$can(this.$abilityActions.READ, this.$abilitySubjects.INCIDENT_STATUS) ||
                this.$can(this.$abilityActions.READ, this.$abilitySubjects.SITE_GROUP) ||
                this.$can(this.$abilityActions.READ, this.$abilitySubjects.EMPLOYEE_GROUP);
        },
        collapseIcon () {
            return this.isMenuOpened ? ICONS.DOUBLE_LEFT : ICONS.DOUBLE_RIGHT;
        },
        miniVariant () {
            return !this.isMenuOpened && !this.isMobile;
        },
        navbarWidth () {
            return this.isMenuOpened ? fullNavbarWidth : miniNavbarWidth;
        },
        cssVars () {
            return {
                '--nav_width': this.navbarWidth,
                '--nav_height': navbarHeight
            };
        },
        isMobile () {
            return this.$vuetify.breakpoint.smAndDown;
        }
    },
    watch: {
        miniVariant: {
            handler (val) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        window.sessionStorage.setItem('miniVariant', val);
                    }, 70);
                });
            },
            immediate: true
        },
        newIncidentsNotification: {
            handler (val) {
                if (val) {
                    this.playSound();
                } else {
                    this.stopSound();
                }
            },
            immediate: true
        }
    },
    methods: {
        toggleMenu () {
            this.isMenuOpened = !this.isMenuOpened;
        },
        closeMenu () {
            this.isMenuOpened = false;
        },
        playSound () {
            this.sound.loop = true;
            this.sound.play();
        },
        stopSound () {
            this.sound.pause();
            this.sound.currentTime = 0;
        }
    },
    created () {
        if (!this.isMobile) {
            const _val = window.sessionStorage.getItem('miniVariant') === 'true';
            this.isMenuOpened = !_val;
        } else {
            this.isMenuOpened = false;
        }
    },
    beforeDestroy () {
        this.$store.commit('incidents/SET_NEW_INCIDENTS_NOTIFICATION', false);
        this.stopSound();
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";

.full-height {
    min-height: 100%;
}

.sidebar {
    transition: width 0.3s, left 0.3s;
    position: fixed;
    width: var(--nav_width);
    top: calc(1px + #{var(--nav_height)});
    height: calc(100vh - #{var(--nav_height)});
    bottom: 0;
    left: 0;
    background-color: $light_grey;
    box-shadow: inset -1px 0 0 #dbdbdb;
    transform: translate3d(0, 0, 0);

    &__wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }

    &__list {
        padding-left: 0;
        list-style: none;
        margin-bottom: 60px;
    }

    &__mobile-item {
        @include navbar_item_title;
    }
}

.collapse {
    width: var(--nav_width);
    overflow: hidden;
    white-space: nowrap;
    border-top: 1px solid $grey_lighten_2;
    transition: width 0.3s;
    margin-bottom: 0;
    padding: 10px 16px;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    background: $tertiary;
    display: flex;
    align-items: center;
    justify-content: left;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .sidebar {
        width: 100%;
        top: 0;
        height: 100vh;
        overflow-y: auto;
        display: block;
        transition: display 0.3s;

        &_closed {
            display: none;
        }

        &__list {
            margin-bottom: 5px;
        }
    }
}
</style>
